<script>
/***
 Name: list
 Code: sa0ChunLuyu
 Time: 2021/11/22 16:34
 Remark: 任务管理 左侧列表
 */
export default {
  data() {
    return {
      list: [],
      size: 20,
      count: 0,
      edit: 1,
      page_first: true,
      list_search: {
        page: 1,
        search: ''
      }
    }
  },
  mounted() {
    // 页面初始化 执行的函数
    this.mountedDo()
  },
  watch: {
    $route: {
      handler() {
        // 深度监听 路由变化
        this.mountedDo()
      },
      deep: true
    },
    list_search: {
      handler() {
        // 深度监听 列表参数 的变量
        if (!this.page_first) {
          // 如果 page_first 是 true (首次打开)  就不会执行
          let {page, search} = this.list_search
          this.routerPush(page, search, this.$route.params.id)
        }
      },
      deep: true
    }
  },
  methods: {
    routerPush(page, search, id, edit) {
      // router 推送
      // page 页数
      // search 搜索名称或者编号
      // id 编辑的 任务ID
      // edit 编辑类型  1-基础信息 2-样品信息
      this.$router.push({
        name: 'originalRecord',
        params: {
          id
        },
        query: {
          page, search, edit, time: new Date() / 1
        }
      })
    },
    mountedDo() {
      // 开始读取 URL 上面的参数 page - 页数 type - 类型 search - 搜索 edit - 编辑类型 1-基础信息 2-样品信息
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      this.edit = this.$route.query.edit ? this.$route.query.edit : 1;
      // 读取完成之后 开始执行 获取 任务委托单列表的函数
      this.getTaskList()
    },
    getTaskList() {
      // 任务委托单 分页列表 https://onespoon.coding.net/p/lims/wiki/426
      // 构建 data
      let data = this.list_search;
      // 执行 POST
      this.$sa0.post({
        url: this.$api('原始记录单模板分页列表'), data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 按照 接口返回的数据 给本地的变量 赋值
            this.list = response.data.list
            this.size = response.data.size
            this.count = Number(response.data.count)
            this.list_search.page = response.data.page
            // 赋值完成 之后  打开 对 list_search 的深度监听
            setTimeout(() => {
              this.page_first = false
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createNewTask(id = 0) {
      this.$sa0.localStorage.set('COPYOTPL', id)
      this.jumpToCreate();
    },
    createNewTplNoTpl(){
      this.$sa0.localStorage.del('COPYOTPL')
      this.jumpToCreate()
    },
    jumpToCreate() {
      this.routerPush(
          this.list_search.page,  // 页数
          this.list_search.search,  // 搜索名称或者编号
          0, // 编辑的 任务ID
          1
      )
    },
    jumpToEdit(id) {
      this.routerPush(
          this.list_search.page,  // 页数
          this.list_search.search,  // 搜索名称或者编号
          id, // 编辑的 任务ID
          1
      )
    },
    //删除w
    delClick(item) {
      this.$confirm({
        title: '确认删除该项?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          let data = {
            id: item.id,
          }
          this.$sa0.post({
            url: this.$api('Del_original_record_tpl'),
            data: data,
          }).then((response) => {
            this.$sa0.response({
              response: response.data,
              then: (res) => {
                layer.msg(res.message)
                this.getTaskList()
              },
              error: (res) => {
                layer.msg(res.message)
              }
            })
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
  }
}
</script>
<template>
  <div>
    <div class="task_title_wrapper">原始记录单模板列表</div>
    <div class="create_button_wrapper mt-2">
      <div @click="createNewTplNoTpl()" class="create_task_wrapper bg-blue-400 text-white cursor-pointer">
        <i class="czs-write-l"></i>
        <span class="ml-2">新建原始记录单模板</span>
      </div>
    </div>
    <div class="task_list_wrapper p-1">
      <div>
        <a-input-search placeholder="搜索" v-model.lazy="list_search.search" class="w-full"/>
      </div>
      <div class="list_main_wrapper px-1">
        <a-list bordered :data-source="list">
          <a-list-item slot="renderItem" slot-scope="item, key">
            <a-dropdown :trigger="['click']">
              <div class="w-full truncate cursor-pointer">
                <a-space>
                  <i class="czs-file-l"></i>
                  <span v-show="item.id !== Number($route.params.id)">{{ item.name }}</span>
                  <b v-show="item.id === Number($route.params.id)">{{ item.name }}</b>
                </a-space>
              </div>
              <a-menu slot="overlay" style="width: 200px;">
                <a-menu-item @click="createNewTask(item.id)" key="0">复制</a-menu-item>
                <a-menu-item @click="jumpToEdit(item.id)" key="2">编辑</a-menu-item>
                <a-menu-item @click="delClick(item)" key="3">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-list-item>
        </a-list>
      </div>
      <div class="list_page_wrapper" v-if="count && count > size">
        <a-pagination simple v-model="list_search.page" :total="count" :defaultPageSize="size"/>
      </div>
    </div>
  </div>
</template>
<style scoped>
.list_page_wrapper {
  position: absolute;
  bottom: 10px;
}

.list_main_wrapper {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  top: 36px;
  overflow-y: auto;
}

.task_list_wrapper {
  width: 250px;
  position: absolute;
  bottom: 0;
  top: 90px;
  border: #eeeeee 1px solid;
  border-radius: 6px;
}

.create_task_wrapper {
  width: 100%;
  float: left;
}

.create_button_wrapper {
  font-weight: bold;
  font-size: 15px;
  width: 250px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  border-radius: 6px;
}

.task_title_wrapper {
  font-size: 24px;
  font-weight: bold;
}

.ant-list-bordered {

  border-radius: 4px 4px 0 0;
}

.ant-list-bordered {
  border: none !important
}

.ant-list-bordered .ant-list-item {
  padding: 10px
}
</style>
