<template>
  <div>
    <table class="layui-table" style="margin: 0;border: none;">
      <colgroup>
        <col width="119">
        <col width="300">
        <col width="80">
      </colgroup>
      <tbody>
      <tr v-for="(vi,vk) in info.content">
        <td>
          {{ vi.name }}
          <span v-if="vk==info.content.length-1">(结果)</span>
        </td>
        <td colspan="2">
          {{ xy(vi.xy) }}
        </td>
        <td>
          <a-space>
            <a-button @click="set(vk)" type="primary" size="small">设置</a-button>
            <a-button @click="del(vk)" type="danger" size="small">移除</a-button>
          </a-space>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "shizecha",
  props: ['info', 'xy', 'set', 'del'],
}
</script>

<style scoped>

</style>
